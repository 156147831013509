@import url('https://fonts.googleapis.com/css2?family=Kodchasan:wght@500&display=swap');

*{
  color: #000;
font-family: Kodchasan;
font-size: 1rem;
font-style: normal;
font-weight: 500;
line-height: 1.125rem; 
}

.mobile-container{
  border-radius: 1.804rem;
  background: #2C2C2C;
  box-shadow: -1.6781489849090576px 0px 4.027557849884033px -2.517223834991455px #212121 inset, 0px -0.8390744924545288px 3.3562979698181152px 0px #212121 inset;
  width: 19.21263rem;
  height: 39.46175rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-screen{
  border-radius: 1.804rem;
  background: #e0e0e0;
  width: 17.50719rem;
  height: 37.31275rem;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-container{
  display: flex;
  padding: 2em 1rem;
  border: 1px solid darkcyan;
  align-items:center ;
  gap : 5rem;
}

.Form-Container{
 width: 40.1875rem;
 height: 38.25rem;
 background: #ebebeb;
 border-radius: 1.804rem;
 box-shadow: .02rem .02rem .01rem .01em #b8b8b8;
 display: flex;
 flex-direction: column;
 justify-content: space-around;
 padding: 1.8rem;
}

.Form-Component-Set{
  display: flex;
  flex-direction: row;
  justify-content: left;
  border: 1px solid red;
  gap: 2em;
}

.Component{
  display: flex;
  flex-direction: column;
  justify-content: left;
  border: 1px solid blue;
  flex-grow: 1;
  padding: .25rem;
  gap: .5rem;
}

.Picker{
  display: flex;
  flex-direction: row;
  justify-content:right;
  border: 1px solid green;
  gap:.5em;
}

.Button{
  display: flex;
  padding: 1rem;
  background: #ffffff;
  border-radius: .3rem;
  cursor: pointer;
  flex-wrap: wrap;
  border: .001rem solid black;
}

.Tip{
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid red;
  
}

.Tip-Text{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #000000d3;
  padding: .5rem;
  color: #ffffff;  
}

.ToolTip{
  display: flex;
  flex-direction: column;
  border: 1px solid green;
  align-items: center;
}